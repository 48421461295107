import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Button from '@mui/material/Button';
import classnames from 'classnames';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import '../styles/SignContract.css';

function SignContractStepPage(){
    const location = useLocation();
    const contract = location.state.selectedContract;
    const lastStep = location.state.lastStep;
   
    const { tid } = useParams();
    const { token } = useParams();
    const navigate = useNavigate();

    function goToPrevPage(){
        navigate("/sign-contract/" + tid + "/" + token, {state:{selectedService: {serviceid: contract.typid}}});
    }
    function goToHome(){
        navigate("/" + tid + "/" + token);
    }
    
    const [stepData, setStepData] = useState([{matrixtext: '', btntwotext: '', btnonetext: ''}]);
    const [activStep, setActivStep] = useState(0);
    const [prevStep, setPrevStep] = useState(0);
    const [showInfo, setShowInfo] = useState(false);
    const [expectedTxt, setExpectedTxt] = useState('');
    const [appHeaderTxt, setAppHeaderTxt] = useState('Vertragsschritte');
    
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

          //Verträge aus DB lesen
          function getMatrix(){            
            fetch(
                //'/getfragematrix/'+contract.matrix+'',
                '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=getfragematrix/" + contract.matrix + "&method=get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
        
            ).then(function(response) {
                return response.json();
            }).then(function(data) {        
                //console.log('matrix', data.matrix)
                setStepData(data.matrix);
            }).catch(function(error) {
                console.log('Request failed', error)
            });
        }

    useEffect(() => {
        //console.log('lastStep', lastStep);
        if(lastStep===0){
            setActivStep(lastStep);
        }else{
            setActivStep(lastStep-1);
        }
       
        if(contract.matrix !==0){
            getMatrix();
        }       
        
        
    }, []);
   
    function handelBtnOne(){
        //console.log('handelBtnOne','ja');
        //Prüfen ob richtige Antwort
        if(stepData[activStep].expected===1 || stepData[activStep].expected===0){
            //console.log('next step', stepData[activStep]['nextbtnone']);
            
                   
            //Prüfen ob nächster Step oder Overview
            if(stepData[activStep]['nextbtnone'] !==0){
                //Antwort in stepData speichern        
                const providedAnswer={...stepData[activStep], answer: 'ja'};
                let newArr = [...stepData];
                newArr[activStep] = providedAnswer;            
                setStepData(newArr);
                postAnwer(providedAnswer);

                //console.log('nextStep');
                setPrevStep(activStep);
                let nextStep = stepData[activStep]['nextbtnone']-1;
                setActivStep(nextStep);
                if(activStep<stepData.length-1){
                    setAppHeaderTxt('Freischaltung');
                    
                }
            }else{
                
                setContractSigned();
                goToHome();
            }
        }else{
            //console.log('falsch');
            setExpectedTxt('Nein');
            handleOpen();
        }
        
    }
    function handelBtnTwo(){
        //console.log('handelBtnTwo','nein');
        //Prüfen ob richtige Antwort
        if(stepData[activStep].expected===2 || stepData[activStep].expected===0){
            //Antwort in stepData speichern        
            const providedAnswer={...stepData[activStep], answer: 'nein'}; 
            let newArr = [...stepData];
            newArr[activStep] = providedAnswer;            
            setStepData(newArr);
            postAnwer(providedAnswer);
            //console.log('stepDataWithAnswer',stepData)
            //Prüfen ob nächster Step oder Overview
            if(activStep<stepData.length-1){
                //console.log('nextStep');
                setPrevStep(activStep);
                let nextStep = stepData[activStep]['nextbtntwo']-1;
                setActivStep(nextStep);
                setAppHeaderTxt('Fehler');
            }else{
                //setShowOverview(true)
                //setContractSigned();
                navigate("/sign-contract/" + tid + "/" + token, {state:{selectedService: {serviceid: contract.typid}}});
                //console.log('von vorne');
            }
        }else{
            //console.log('falsch');
            setExpectedTxt('Ja')
            handleOpen();
        }        
    }

    function previusStep(){
        //console.log('previusStep',activStep);
        if(activStep>0){
            //console.log('previusStep');            
            setActivStep(prevStep);
        }else{
            goToPrevPage();
        }

    }

    function postAnwer(answer){
        answer['terminal']=tid;
        answer['contractid']=contract.contractid;
        fetch(
            //'/setmatrixanswers',
           
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=setmatrixanswers&method=post&data=" + JSON.stringify(answer),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            //console.log('postdata', data)
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }
    

    function setContractSigned(){
        let postData={'contractid': contract.contractid, 'terminalid': tid, 'servicelId': contract.typid};
        fetch(           
            
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=setcontractsigned&method=post&data=" + JSON.stringify(postData),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {            
            //navigate("/sign-contract/" + tid + "/" + token, {state:{selectedService: {serviceid: contract.typid}}});
        }).catch(function(error) {
            console.log('Request failed', error)
        });
       
    }

    function gotoQuestions(){
        setShowInfo(false);
    }

    function showQuestion(){
        //console.log('showInfo', showInfo);
        if(showInfo===false && stepData[activStep] !== undefined){
            return(
                <div className="AppContainer">
                <div className="AppHeader">
                    <div className="AppHeaderLeft">
                        <ArrowBackIosIcon fontSize="large" onClick={previusStep} />
                    </div>
                    <div className="AppHeaderCenter">
                        <h1>{appHeaderTxt}</h1>
                    </div>
                    <div className="AppHeaderRight">
                        <HomeIcon fontSize="large" onClick={goToHome} />
                     </div>  
                     
                </div>
                <div className="QestionContainer">
                    <p className="matrixheaders">{stepData[activStep].headlinetxt}</p>
                    <p className="QuestionText">{stepData[activStep].matrixtext}</p>
                    <p className="matrixheaders">{stepData[activStep].headlinehinweis}</p>
                    <p className="QuestionText">{stepData[activStep].matrixhinweis}</p>
                    
                       
                </div> 
                <div className="FooterContainer">
                <div className="AntwortButtonContainer"> 
                        <button className={classnames(
                                'footerBtnNegativ',
                                {'button-hidden': stepData[activStep].btntwotext==="hide"}    
                            )} onClick={handelBtnTwo}>
                                {stepData[activStep].btntwotext}
                            </button>
                            <button className={classnames(
                                'footerBtnPositiv',
                                {'button-hidden': stepData[activStep].btnonetext==="hide"}    
                            )} onClick={handelBtnOne}>
                                {stepData[activStep].btnonetext}
                            </button>
                            
                       
                           
                     </div>  
                </div>
                
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={styleModal}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Anwort überprüfen.
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                               Diese Frage muss mit {expectedTxt} beantwortet werden.
                            </Typography>
                            <Button variant="contained" size="large"onClick={handleClose}>
                                OK
                             </Button>  
                        </Box>
                    </Modal>
                </div>
    
            </div>
               
            );
        }else{
            
            return(
                <div className="AppContainer">
                <div className="AppHeader">
                    <div className="AppHeaderLeft">
                        <ArrowBackIosIcon fontSize="large" onClick={previusStep} />
                    </div>
                    <div className="AppHeaderCenter">
                        <h1>{appHeaderTxt}</h1>
                    </div>
                    <div className="AppHeaderRight">
                        <HomeIcon fontSize="large" onClick={goToHome} />
                     </div>  
                     
                </div>
                <div className="QestionContainer">
                    <p className="QuestionText">Das von Ihnen eingesetzte Personal muss aufgrund von diversen gesetzlichen Anforderungen auf seine Zuverlässigkeit hin bewertet werden. 
                        Hierzu stellen wir Ihnen Fragen. Bitte beantworten sie diese mit JA oder NEIN.</p>
                    <p className="QuestionText">
                        Wenn Sie optionale Fragen mit NEIN beantworten, hat dies keine Auswirkung auf die Freischaltung des IDnow Shop Ident Services</p>
                </div> 
                <div className="AntwortButtonContainer">                              
                    <Button variant="contained" size="large" onClick={gotoQuestions}>
                        Ich habe Verstanden
                    </Button>           
                </div>  
    
            </div>
            );
        }
    }

    
    return(
        showQuestion()
       
    );
}
export default SignContractStepPage;




