
import classnames from 'classnames';

import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function SignContractListItem(contractElement){
    //console.log('SignContractListItem',contractElement);
   
    
    function goToContract(){
        contractElement.itemToList(contractElement);           
    }

    return(
        <div className="ContractCard" onClick={() => goToContract(contractElement)} >        
            <div className={classnames(
                'ContractCardText',
                {'ButtonCardText disabled': contractElement.signed !== undefined})}> 
                <h3>{contractElement.name}</h3>          
            </div>
            <div className={classnames(
                'ButtonStatusDone',
                {'ButtonStatusDone hideIcon': contractElement.signed === undefined})}>  
            
                <CheckIcon sx={{ fontSize: 25, color: '#00db43'}}  />
            </div>
            <div className={classnames(
                'ButtonStatusUnfinished',
                {'ButtonStatusUnfinished hideIcon': contractElement.unfinished !== true || contractElement.signed !== undefined})}>
            
                <AccessTimeIcon sx={{ fontSize: 25, color: '#ffa60a'}}  />
            </div> 
        </div>
    );
        
}
export default SignContractListItem;

