import { useNavigate } from "react-router-dom";
import classnames from 'classnames';

function AvailableServicesListItem(serviceItem){
    const navigate = useNavigate();
    
    function goToContract(serviceItem){
        navigate("/sign-contract/" + serviceItem.tid + "/" + serviceItem.token, {state:{selectedService: serviceItem}});       
    }
    
    return(
        <div className="ContractCard" onClick={() => goToContract(serviceItem)} >        
            <div className="ButtonCardImage">
                <img src={serviceItem.serviceimage} alt={serviceItem.name} />
            </div>
            <div className={classnames(
                    'ButtonCardText',
                    {'ButtonCardText-disabled': serviceItem.status===3}    
                )}> 
                <h3>{serviceItem.name}</h3>            
            </div>  
        </div>
    );
        
}
export default AvailableServicesListItem;


