import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SignContractList from "../component/SignContracts/SignContractList";



import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import '../styles/SignContract.css';
import  '../styles/App.css';

function SignContractPage(){
    const navigate = useNavigate();
    const locationService = useLocation();
    const selectedService = locationService.state.selectedService;
    const { tid } = useParams();
    const { token } = useParams();

   
    const [contracts, setContracts] = useState([]);
    //console.log('selectedService',selectedService);
    
    function goBack(){
        navigate("/available-services/" + tid + "/" + token);
    }

    function gotoHome(){
        navigate("/" + tid + "/" + token);
    }

    function checkTokenFunction(){     
        fetch(
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=checktoken&method=post&terminal=" + tid + "&token=" + token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            if(data.check != "ok"){
                navigate("/noToken");
           }else{
            getContracts();
           }
           const checkResult = data.check;
          
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });  

    }

    //Verträge aus DB lesen
    function getContracts(){
        fetch(           
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=getcontractforservice/" + tid + "/" + selectedService.serviceid + "&method=get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            let listArray = {'selectedService':selectedService, 'contracts':data.services};
            setContracts(listArray);
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }
    
    useEffect(() => {
        checkTokenFunction();
        
    }, []);
  
   return(
    <div className="AppContainer">
        <div className="AppHeader">
                <div className="AppHeaderLeft">
                    <ArrowBackIosIcon sx={{ fontSize: 25}} onClick={goBack} />
                </div>
                <div className="AppHeaderCenter">
                    <h1>Service {selectedService.name}</h1>
                </div>
                <div className="AppHeaderRight">
                    <HomeIcon sx={{ fontSize: 25}} onClick={gotoHome} />
                 </div>  
                 
            </div>
       
        <div className="headertxt">
            <p>Um den Service {selectedService.name} nutzen zu können müssen folgende Verträge/Vereinbarungen abgeschlossen werden.</p>
            <p>Bitte tippen Sie unten auf die Verträge/Vereinbarungen und stimmen diesen mit dem Button ANMELDEN zu.</p>
        </div>
       
        <div className="ContractList">
            <SignContractList  itemlist={contracts}/>
        </div>

</div>
      

   )
    
}
export default SignContractPage;