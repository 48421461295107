import { useParams } from "react-router-dom";
import AvailableServicesListItem from "./AvailableServicesListItem";
import { List } from "@mui/material";
function AvailableServicesList(props) {
    const { availableServices } = props;
    const { tid } = useParams();
    const { token } = useParams();
   //console.log('AvailableServicesList', tid);

    return (
        <List className="ContractCardContainer">
            {availableServices.map((serviceItem) => (
                <AvailableServicesListItem 

                    key={serviceItem.id}
                    name={serviceItem.servicename}
                    status={serviceItem.status}
                    serviceid={serviceItem.serviceid}
                    serviceimage={serviceItem.serviceimage}
                    tid={tid}
                    token={token}
                   
                />
            ))}
        </List>
    );
}
export default AvailableServicesList;