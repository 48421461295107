import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import StartPage from './pages/Start';
import AvailableServiceListPage from './pages/AvailableServicePage';
import SignedContractListPage from './pages/SignedContracPage';
import SignContractPage from './pages/SignContractPage';
import SignContractStepPage from './pages/SignContractStepPage';
import ShowContractPage from './pages/ShowContractPage';
import SignContractHowToPage from './pages/SignContractHowToPage';
import NoTokenPage from './pages/NoTokenPage';



function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/:tid/:token" element={<StartPage />} />
          <Route path="/available-services/:tid/:token" element={<AvailableServiceListPage />} />
          <Route path="/signed-contracts/:tid/:token" element={<SignedContractListPage />} />
          <Route path="/sign-contract/:tid/:token" element={<SignContractPage />} />
          <Route path="/sign-contract-step/:tid/:token" element={<SignContractStepPage />} />

          <Route path="/sign-contract-howto/:tid/:token" element={<SignContractHowToPage />} />

          <Route path="/show-contract/:tid/:token" element={<ShowContractPage />} />
          <Route path="/noToken" element={<NoTokenPage />} />

          <Route path="/:tid" element={<NoTokenPage />} />
          <Route path="/available-services/:tid" element={<NoTokenPage />} />
          <Route path="/signed-contracts/:tid" element={<NoTokenPage />} />
          <Route path="/sign-contract/:tid" element={<NoTokenPage />} />
          <Route path="/sign-contract-step/:tid" element={<NoTokenPage />} />
          <Route path="/show-contract/:tid" element={<NoTokenPage />} />

        </Routes>
      </Router>
     
    </div>
  );
}

export default App;
