import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";


import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';

import ShowContract from '../component/ShowContract/ShowContract';

import ShowContractMail from '../component/ShowContract/ShowContractMail';
import MailSuccess from '../component/ShowContract/MailSuccess';



import '../styles/ContractList.css';
import  '../styles/App.css';


function ShowContractPage(){
    const locationShowContract = useLocation();
    const contract = locationShowContract.state.selectedContract;
    const resumeContract = locationShowContract.state.resume;
    console.log('ShowContractPage contract',contract);
    console.log('ShowContractPage resumeContract',resumeContract);
    const { tid } = useParams();
    const { token } = useParams();
   //console.log('ShowContractPage contract',contract);
  
    const navigate = useNavigate();
  
    const [showComp, setShowComp] = useState(resumeContract);
    
    useEffect(() => { 
        if(resumeContract===4){
            //letzte Antwort holen
            const lastAntw=contract['answers'][contract['answers'].length-1]['matrixstepid'];
            //console.log('lastAntw',lastAntw);
            navigate("/sign-contract-step/" + tid + "/" + token, {state:{selectedContract: contract, lastStep: lastAntw}});          
        }       
       
    }, [resumeContract]); 

   function goBack(){    
        if(showComp === 1 || showComp === 2){
            setShowComp(0);
        } else {  
            navigate("/sign-contract/" + tid + "/" + token, {state:{selectedService: {serviceid: contract.typid}}});
        } 
      
    }
    function gotoHome(){       
        navigate("/" + tid + "/" + token);
    }

    const childToParent = (childdata) => {
        //console.log('childdata',childdata);
        if(childdata===5){//Abbrechen
            navigate("/sign-contract/" + tid + "/" + token, {state:{selectedService: {serviceid: contract.typid}}});
        }else{
            setShowComp(childdata.hasMatrix);
        }
    }

    function showCompSelect(){
        if(showComp === 0){
            return <ShowContract childToParent={childToParent} parentToChild={contract}/>
        } 
        else if(showComp === 1){
            //zusimmung
            setContractSigned();
        }       
        else if(showComp === 2){
            return <ShowContractMail childToParent={childToParent}/>
        }
        else if(showComp === 3){
            return <MailSuccess childToParent={childToParent}/>
        }
        else if(showComp === 4){
            //console.log('goto Steps');
            navigate("/sign-contract-step/" + tid + "/" + token, {state:{selectedContract: contract, lastStep: 0}});
        }
    }    

    function setContractSigned(){
        let postData={'contractid': contract.contractid, 'terminalid': tid, 'servicelId': contract.typid};
        fetch(           
            
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=setcontractsigned&method=post&data=" + JSON.stringify(postData),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {            
            navigate("/sign-contract/" + tid + "/" + token, {state:{selectedService: {serviceid: contract.typid}}});
        }).catch(function(error) {
            console.log('Request failed', error)
        });
       
    }
    return(
      <div className="AppContainer">
        <div className="AppHeader">
            <div className="AppHeaderLeft"> 
                <ArrowBackIosIcon sx={{ fontSize: 25}} onClick={goBack} />
            </div>
            <div className="AppHeaderCenter">
                <h1>Vertrag prüfen</h1>
            </div>
            <div className="AppHeaderRight">
                <HomeIcon sx={{ fontSize: 25}} onClick={gotoHome} />
            </div>  
           
        </div>
     
        <div className="showVertragContainer">
            {showCompSelect()}            
        </div>

    </div>
   )

}
export default ShowContractPage;