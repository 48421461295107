
import { useEffect, useState } from "react";

import Button from '@mui/material/Button';

function ShowContract({childToParent, parentToChild}) {
    const [contractHtml, setContractHtml] = useState('');
    const [hasMatrix, setHasMatrix] = useState(1);
    const [buttonTxt, setButtonTxt] = useState('Loading...');
    const [isLoaded, setIsLoaded] = useState(false);
    
    function getContractHtml(){
        fetch(           
            
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=getsinglecontract/" + parentToChild.contractid +"&method=get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {             
            setContractHtml(data.contract[0].contracthtml);
            //Festlegen ob Matrix
            if(data.contract[0].matrix === 1){
                setHasMatrix(4);
                setButtonTxt('ANMELDEN');
                setIsLoaded(true);
            }else{               
                setButtonTxt('ANMELDEN');
                setIsLoaded(true);
            }
            
           
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }

    useEffect(() => {
        getContractHtml();
    }, []);



   const renderContract =(
        <div className="mailContainer">
            <div className='pdfContainer'>
                <div className='vertragHtml' dangerouslySetInnerHTML={{ __html: contractHtml }} />                
            </div>
            <div className="SendButtonContainer"> 
                <button className="footerBtnNegativ" onClick={() => childToParent(5)}>
                    Abbrechen
                </button>
                <button className="footerBtnPositiv" onClick={() => childToParent({hasMatrix})}>
                    {buttonTxt}
                </button>
            
            </div>
        </div>
   );
    return (
        {isLoaded} ? renderContract : { }
       
        
    );
   
   
   
}
export default ShowContract;