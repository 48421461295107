import { useNavigate } from "react-router-dom";
import classnames from 'classnames';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { green, lightBlue } from "@mui/material/colors";


import { useState, useEffect } from 'react';

function StartButtons({tid,token, unseen}) {

    const [currentTime, setCurrentTime] = useState(10);
    
    
    useEffect(() => {
        const interval = setInterval(() => {
            //console.info("interval", currentTime);
          
          if (currentTime > 0) {
            setCurrentTime((prevCurrentTime) => prevCurrentTime - 1);
          }else{
            clearInterval(interval)
          }
          
        }, 1000);
        return () => clearInterval(interval);
      }, [currentTime]);


    //console.log('Buttons tid',tid)
    //console.log('Buttons unseen',unseen)

    const navigate = useNavigate();
    function goToMyContracts(){
        navigate("/signed-contracts/" + tid + "/" + token);
    }
    function goToAvailableServices(){
        navigate("/available-services/" + tid + "/" + token);
    }
    
   
    return(
        <div className="ButtonContainer">
            
            <div className="ButtonCard" onClick={goToAvailableServices}>
                <div className="ButtonCardHeaderIcon">
                <ReceiptLongIcon sx={{ fontSize: 40, color: lightBlue[500]}}/>
                </div>
                <div className="ButtonCardText">
                    <h3>Neue Verträge</h3>
                    <p>Neuen Vertrag abschließen</p>
                </div>
            </div>
            <div className="ButtonCard" onClick={goToMyContracts}>
                <div className="ButtonCardHeaderIconContainer">
                    <div className="ButtonCardHeaderIcon">                    
                        <HistoryEduIcon sx={{ fontSize: 40, color: lightBlue[500]}}/>
                    </div>
                    <div className={classnames(
                        'ButtonCardNewIcon',
                        {'hide-element': unseen===false}    
                    )}>                    
                        <FiberNewIcon sx={{ fontSize: 20, color: green[500]}}/>
                    </div>
                </div>  
               
                <div className="ButtonCardText"> 
                    <h3>Abgeschlossene Verträge</h3>
                    <p>Verwalten Sie Ihre Verträge</p>
                </div>                
            </div>
          
        </div>
 
    )
                    
}
export default StartButtons;