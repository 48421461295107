import React, { useRef, useState, useEffect } from "react";

import Button from '@mui/material/Button';

import Keyboard from "react-simple-keyboard";
import 'simple-keyboard/build/css/index.css';

function MailSuccess({childToParent}) {
    return (
        <div className="mailContainer">
            <div className="inputContainer">
                <h1>Vertrag erfolgreich versendet</h1>
                   
            </div>
        <div className="SendButtonContainer"> 
            <Button variant="outlined" size="large" onClick={() => childToParent(4)}>
               Weiter
            </Button>  
        </div>
      </div>
    );
}
export default MailSuccess;