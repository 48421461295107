import { useParams } from "react-router-dom";
import ActivServicesListItem from "./ActivServicesListItem";
import { List } from "@mui/material";

function ActivServicesList(props) {
    const { activServices } = props;
    const { tid } = useParams();
    const { token } = useParams();
   console.log('activServices', tid);

    return (
        <div>

        <List className="ContractCardContainer">
            {activServices.map((serviceItem) => (
                <ActivServicesListItem 

                    key={serviceItem.id}
                    name={serviceItem.servicename}
                    status={serviceItem.status}
                    serviceid={serviceItem.serviceid}
                    serviceimage={serviceItem.serviceimage}
                    changedate={serviceItem.changedate}
                    tid={tid}
                    token={token}
                   
                />
            ))}
        </List>
    
        </div>
        
    );    
}
export default ActivServicesList;