import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivServicesList from "../component/ActivServices/ActivServicesList";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import '../styles/ContractList.css';
import  '../styles/App.css';


function SignedContractListPage(){
    const navigate = useNavigate();
    
    const [activServices, setActivServices] = useState([]);

    const { tid } = useParams();
    const { token } = useParams();

    //console.log(tid);
    function goToStart(){
        navigate("/" + tid + "/" + token);
    }

    function checkTokenFunction(){     
        fetch(
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=checktoken&method=post&terminal=" + tid + "&token=" + token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            if(data.check !== "ok"){
                navigate("/noToken");
           }else{
            getActivContracts();
           }
          
          
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });  

}
    
    //Verfügbare Verträge aus DB lesen
    function getActivContracts(){        
        fetch(
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=getactiveservices/" + tid + "&method=get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            //console.log(data);
            setActivServices(data.services);
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }
    useEffect(() => {
        checkTokenFunction();
        getActivContracts();
    }, []);


    return(
        <div className="AppContainer">
            <div className="AppHeader">
                <div className="AppHeaderLeft">
                    <ArrowBackIosIcon sx={{ fontSize: 25}} onClick={goToStart} />
                </div>
                <div className="AppHeaderCenter">
                    <h1>Abgeschlossene Services</h1>
                </div>
                <div className="AppHeaderRight">
                    <HomeIcon sx={{ fontSize: 25}} onClick={goToStart} />
                 </div>  
                 
            </div>
           
             <div className="ContractList">
                <ActivServicesList activServices={activServices}/>
                {activServices.length ===0 && <p>Sie haben noch keine abgeschlossenen Verträge.</p>}
               
            </div>
            
       

        </div>
    );
    
}
export default SignedContractListPage;