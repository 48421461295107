import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AvailableServicesList from "../component/AvailableServices/AvailableServicesList";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import '../styles/ContractList.css';
import  '../styles/App.css';


function AvailableServiceListPage(){
    const navigate = useNavigate();
    const [availableServices, setAvailableServices] = useState([]);
   
    const { tid } = useParams();
    const { token } = useParams();

    //console.log(tid);
    function goToStart(){
        navigate("/" + tid + "/" + token);
    }
    
     function gotoHome(){
        navigate("/" + tid + "/" + token);
    }

    function checkTokenFunction(){     
        fetch(
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=checktoken&method=post&terminal=" + tid + "&token=" + token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            if(data.check != "ok"){
                navigate("/noToken");
           }else{
            getAvailableServices();
           }
           const checkResult = data.check;
          
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });  

    }

    //Verfügbare Verträge aus DB lesen
    function getAvailableServices(){        
        fetch(
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=getavailservices/" + tid + "&method=get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            //console.log(data);
            setAvailableServices(data.services);
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }
    useEffect(() => {
        checkTokenFunction();
        
    }, []);


    return(
        <div className="AppContainer">
            <div className="AppHeader">
                <div className="AppHeaderLeft">
                    <ArrowBackIosIcon sx={{ fontSize: 25}} onClick={goToStart} />
                </div>
                <div className="AppHeaderCenter">
                    <h1>Verfügbare Services</h1>
                </div>
                <div className="AppHeaderRight">
                    <HomeIcon sx={{ fontSize: 25}} onClick={gotoHome} />
                 </div>  
                 
            </div>
           
             <div className="ContractList">
                <AvailableServicesList availableServices={availableServices}/>
            </div>
            
       

        </div>
    );
}
export default AvailableServiceListPage;
