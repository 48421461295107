import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import '../styles/ContractList.css';
import  '../styles/App.css';

function SignContractHowToPage(){

    const locationShowContract = useLocation();
    const contract = locationShowContract.state.selectedContract;
    const resumeContract = locationShowContract.state.resume;
    console.log('ShowContractPage contract',contract);
    const { tid } = useParams();
    const { token } = useParams();

    const navigate = useNavigate();
    function goBack(){    

            navigate("/sign-contract/" + tid + "/" + token, {state:{selectedService: {serviceid: contract.typid}}});
    
      
    }
    function gotoHome(){       
        navigate("/" + tid + "/" + token);
    }

    function nextStep(){
        //navigate("/show-contract/" + tid + "/" + token, {state:{selectedContract: contract}});
        navigate("/show-contract/" + tid + "/" + token, {state:{selectedContract: contract, resume: 0}});
    }

    return(
        <div className="AppContainer">
        <div className="AppHeader">
            <div className="AppHeaderLeft"> 
                <ArrowBackIosIcon sx={{ fontSize: 25}} onClick={goBack} />
            </div>
            <div className="AppHeaderCenter">
                <h1>Vertragsschritte</h1>
            </div>
            <div className="AppHeaderRight">
                <HomeIcon sx={{ fontSize: 25}} onClick={gotoHome} />
            </div>  
           
        </div>
     
        <div className="QestionContainer">
               <p className="matrixheaders">1. Zusatzvereinbarung</p> 
               <p className="QuestionText">Stimmen Sie der Zusatzvereinbarung mit dem Button ANMELDEN zu.</p>
               <p className="matrixheaders">2. Fragen beantworten</p> 
               <p className="QuestionText">Die Zuverlässigkeit des Personals muss aufgrund von gesetzlichen Anforderungen bewertet werden. Hierzu müssen Fragen beantwortet werden.</p>      
        </div>
        <div className="SendButtonContainer"> 
               
               
                <button className="footerBtnPositiv"onClick={nextStep}>
                    ICH HABE VERSTANDEN
                </button>
            
            </div>

    </div>
    );
}
export default SignContractHowToPage;