import { useNavigate } from "react-router-dom";
import classnames from 'classnames';
import dayjs from 'dayjs';


function ActivServicesListItem(serviceItem){
    const navigate = useNavigate();
    
    function showDetails(serviceItem){
        console.log('showDetails',serviceItem);       
    }
    
    return(
        <div className="ContractCard" onClick={() => showDetails(serviceItem)} >        
            <div className="ButtonCardImage">
                <img src={serviceItem.serviceimage} alt={serviceItem.name} />
            </div>
            <div className={classnames(
                    'ButtonCardText',
                    {'ButtonCardText-disabled': serviceItem.status===3}    
                )}> 
                <h3>{serviceItem.name}</h3>
                <p>Abgeschlossen am: <br/>{dayjs(serviceItem.changedate).format("DD.MM.YYYY HH:mm")}</p>
               
            </div>  
        </div>
    );
        
}
export default ActivServicesListItem;


