import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';
import {useRef} from 'react';
//import Android from '../Android';

function AppHeader({addText}) {

    var Android = window.Android;
    
    function leaveApp(){
        console.log('leaveApp');
        
        Android.sendData(JSON.stringify({
            status: "EXIT"

        }));
       
        
     }

   
    
     return(
        <div className="AppHeader">
            <div className="AppHeaderLeft">
                <ArrowBackIosIcon sx={{ fontSize: 25}} onClick={leaveApp} />
            </div>
            <div className="AppHeaderCenter">
                <h1>{addText}</h1>
            </div>
            <div className="AppHeaderRight">
                <CloseIcon sx={{ fontSize: 25}} onClick={leaveApp} />
            </div>         
        </div>
     )
}
export default AppHeader;