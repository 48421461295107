import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StartButtons from "../component/StartButtons";
import AppHeader from "../component/AppHeader";
import { useNavigate } from "react-router-dom";

import "../styles/App.css";


function StartPage(){
    const navigate = useNavigate();
    const { tid } = useParams();
    const { token } = useParams();   
    
    const [unseenServices, setUnseenServices] = useState(false);
    
    var Android = window.Android;
    
    function appLoaded(){
        //console.log('App loaded');
        if(Android !== undefined){
            Android.sendData(JSON.stringify({
                status: "PAGE_LOADED"
    
            }));
        }        
     }

     function checkTokenFunction(){     
            fetch(
                '/php/getData_slim.php',
                {
                    method: 'POST',
                    mode: 'cors',
                    body: "route=checktoken&method=post&terminal=" + tid + "&token=" + token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin':'*'
                    }
                }
        
            ).then(function(response) {
                return response.json();
            }).then(function(data) {        
                if(data.check !== "ok"){
                    navigate("/noToken");
               }else{
                checkUnseenContracts();
               }
             
              
                
            }).catch(function(error) {
                console.log('Request failed', error)
            });  
    
    }
         

    function checkUnseenContracts(){        
        fetch(
            '/php/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=getunseenservices/" + tid + "&method=get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            
            setUnseenServices(data.unseen);
            //console.log(data);
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }


    useEffect(() => {
        checkTokenFunction();        
        appLoaded();
    }, []);

    return(

        <div className="AppContainer">

            <AppHeader  addText="Lekkerland Vertragscenter"/>
           
            <StartButtons tid={tid} token={token} unseen={unseenServices}/>
        </div>
    );
}
export default StartPage;
