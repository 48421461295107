import SignContractListItem from "./SignContractListItem";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { List } from "@mui/material";

function SignContractList(props) {
    const [contracts, setContracts] = useState([]);
    const navigate = useNavigate(); 
    const { itemlist } = props;
    const { tid } = useParams();
    const { token } = useParams(); 

    const [modalOpen, setModalOpen] = useState(false);
    const [itemdataState, setItemdataState] = useState([]);
    
    
    useEffect(() => { 
        if(itemlist.contracts){
            setContracts(itemlist.contracts);          
        }       
       
    }, [itemlist.contracts]);   

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const itemToList = (itemdata) => {        
        setItemdataState(JSON.parse(JSON.stringify(itemdata)))
        console.log('itemdata', itemdata);
        if(itemdata.signed === undefined){
            //prüfen ob Vertrag begonnen wurde
            if(itemdata.unfinished===true){
                setModalOpen(true);
            }else{               
                const resume = 0;
                console.log('Contract not unfinished', );
                //Prüfen ob Matrix
                if(itemdata.matrix === 1){
                    navigate("/sign-contract-howto/" + tid + "/" + token, {state:{selectedContract: JSON.parse(JSON.stringify(itemdata)), lastStep: 0}});
                }else{                    
                    navigate("/show-contract/" + tid + "/" + token, {state:{selectedContract: JSON.parse(JSON.stringify(itemdata)), resume: resume}});
                }
               
                
            }
            
        }   
       
    }

   

    function newSelected(){
        setModalOpen(false);    
        const resume = 0;
        navigate("/show-contract/" + tid + "/" + token, {state:{selectedContract: itemdataState, resume: resume}});
     
    }
    function resumeSelected(){
        setModalOpen(false);
        const resume = 4;        
        navigate("/show-contract/" + tid + "/" + token, {state:{selectedContract: itemdataState, resume: resume}});       
    }

    return (
        <div>
        <List className="ContractCardContainer">
           {contracts.map((contractElement) => (
            <SignContractListItem 

                key={contractElement.id}
                contractid={contractElement.contractid}
                name={contractElement.name}
                typid={contractElement.typid}
                matrix={contractElement.matrix}
                version={contractElement.version}
                signed={contractElement.signed}
                unfinished={contractElement.unfinished}
                answers={contractElement.answers}
                itemToList={itemToList}
            />
        ))}          
           
        </List>
                <div>
                <Modal
                    open={modalOpen}
                    onClose={newSelected}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={styleModal}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Fortsetzen?
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                           Sie haben bereits mit dem beantworten der Fragen begonnen. Möchten Sie fortfahren oder erneut beginnen?
                        </Typography>
                        <div className="AntwortModalContainer"> 
                        <button className="footerBtnPositiv" onClick={resumeSelected}>Fortsetzen</button>
                        <button className="footerBtnNegativ" onClick={newSelected}>Erneut beginnen</button>
                        </div>
                    </Box>
                </Modal>
            </div>
            </div>
    );
}
export default SignContractList;
