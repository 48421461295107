import React, { useRef, useState, useEffect } from "react";

import Button from '@mui/material/Button';

import Keyboard from "react-simple-keyboard";
import 'simple-keyboard/build/css/index.css';

function ShowContractMail({childToParent}) {
    const [input, setInput] = useState("");
    const [layoutName, setLayoutName] = useState("default");
    
    const keyboard = useRef();

    const ownLayout ={
        default : [ 
            " 1 2 3 4 5 6 7 8 9 0",
            "q w e r t z u i o p",
            "a s d f g h j k l",
            "y x c v b n m . - _ .de .com",
            "@ {space} {backspace}"
        ],
        shift: [
            "Q W E R T Z U I O P",
            "A S D F G H J K L",
            "{shift} Y X C V B N M {backspace}",
            "{numbers} {space} {ent}"
          ],          
        numbers: ["1 2 3", "4 5 6", "7 8 9", "{abc} 0 {backspace}"]
    }
    const ownDisplay = {
        "{numbers}": "123",
        "{ent}": "return",
        "{escape}": "esc ⎋",
        "{tab}": "tab ⇥",
        "{backspace}": "⌫",
        "{capslock}": "caps lock ⇪",
        "{shift}": "⇧",
        "{controlleft}": "ctrl ⌃",
        "{controlright}": "ctrl ⌃",
        "{altleft}": "alt ⌥",
        "{altright}": "alt ⌥",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘",
        "{abc}": "ABC",
        "{space}": " ",
    }
    const [layout, setLayout] = useState(ownLayout);
    const [display, setDisplay] = useState(ownDisplay);
    
    useEffect(() => {
        setLayout(ownLayout);
        setDisplay(ownDisplay);
    }, []);


    const onChange = input => {
        setInput(input);
        console.log("Input changed", input);
    };
    
    const handleShift = () => {
        const newLayoutName = layoutName === "default" ? "shift" : "default";
        setLayoutName(newLayoutName);
        console.log('shift',keyboard.current.layout)
    };    
      
    function handleNumbers() {       
        const newLayoutName = layoutName !== "numbers" ? "numbers" : "default";
        console.log('newLayoutName',newLayoutName);
        setLayoutName(newLayoutName);
        
    }
    
    const onKeyPress = button => {
        console.log("Button pressed", button);
    
        if (button === "{shift}" || button === "{lock}") handleShift();
        if (button === "{numbers}" || button === "{abc}") handleNumbers();
    };
    
    const onChangeInput = event => {
        const input = event.target.value;
        setInput(input);
        keyboard.current.setInput(input);
    };

    function sendMail(){
        if(input === ""){
            alert('Bitte geben Sie eine Mailadresse ein');
            return;
        }
        console.log('sendMail', input);
    }
    
    return (
        <div className="mailContainer">
            <div className="inputContainer">
                <h1>Vertrag per Mail senden</h1>
                    <input className="mailInput"
                    value={input}
                    placeholder={"Bitte geben Sie Ihre Mailadresse an"}
                    onChange={onChangeInput}
                />
                <Keyboard
                    keyboardRef={r => (keyboard.current = r)}
                    
                    layoutName={layoutName}
                    layout={layout}
                    display={display}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                />
            </div>
        <div className="SendButtonContainer"> 
            <Button variant="outlined" size="large" onClick={() => childToParent(3)}>
                Jetzt senden
            </Button>  
        </div>
      </div>
      
      );
}
export default ShowContractMail;